<template>
  <auth-card btn-text="Submit"
    btn-classes="full-width"
    :heading-text="$t('aws.onboarding')"
    @btn-click="submitForm">
    <template #description>
      <el-form ref="awsOnboardingForm"
        :model="awsOnboardingForm"
        :rules="awsOnboardingRules"
        @submit.native.prevent="submitForm">
        <el-form-item prop="email"
          :label="$t('general.email')"
          :label-width="labelWidth"
          class="my-2">
          <el-input v-model="awsOnboardingForm.email"
            :placeholder="$t('general.email')" />
        </el-form-item>

        <el-form-item prop="firstName"
          :label="$t('general.first-name')"
          :label-width="labelWidth"
          class="my-2">
          <el-input v-model="awsOnboardingForm.firstName"
            :placeholder="$t('general.first-name')" />
        </el-form-item>

        <el-form-item prop="lastName"
          :label="$t('general.last-name')"
          :label-width="labelWidth"
          class="my-2">
          <el-input v-model="awsOnboardingForm.lastName"
            :placeholder="$t('general.last-name')" />
        </el-form-item>

        <el-form-item prop="mobile"
          :label="$t('general.mobile')"
          :label-width="labelWidth"
          class="my-2">
          <el-input v-model="awsOnboardingForm.mobile"
            :placeholder="$t('general.mobile')" />
        </el-form-item>

        <el-form-item prop="companyName"
          :label="$t('general.company-name')"
          :label-width="labelWidth"
          class="my-2">
          <el-input v-model="awsOnboardingForm.companyName"
            :placeholder="$t('general.company-name')" />
        </el-form-item>
      </el-form>

      <p>{{ $t('aws.onboarding-reachout') }}</p>
      <i18n path="aws.onboarding-questions"
        tag="p">
        <template #email>
          <a href="mailto:aws-marketplace-enquiry@megaport.com"
            target="_blank">
            aws-marketplace-enquiry@megaport.com
          </a>
        </template>
      </i18n>
      <p>{{ $t('aws.onboarding-info') }}</p>
    </template>
  </auth-card>
</template>

<script>
// NOTE: This onboarding page may not be around for long.
import sdk from '@megaport/api-sdk'

import captureSentryError from '@/utils/CaptureSentryError'
import AuthCard from './ui-components/AuthCard.vue'

export default {
  name: 'AwsOnboarding',

  components: {
    'auth-card': AuthCard,
  },

  data() {
    return {
      awsOnboardingForm: {
        email: '',
        firstName: '',
        lastName: '',
        mobile: '',
        companyName: '',
      },
      awsOnboardingRules: {
        email: [
          { required: true, message: this.$t('validations.required', { thing: this.$t('general.email') }), trigger: 'blur' },
          { type: 'email', message: this.$t('validations.email-invalid'), trigger: 'blur' },
        ],
      },
      labelWidth: '120px',
    }
  },

  computed: {
    /**
     * Get registrationToken from route query param
     */
    registrationToken() {
      return this.$route.query.registrationToken || ''
    },
  },

  methods: {
    submitForm() {
      this.$refs['awsOnboardingForm'].validate(valid => {
        if (!valid) return

        const payload = {
          ...this.awsOnboardingForm,
          registrationToken: this.registrationToken,
        }

        sdk.instance.awsOnboarding(payload)
          .then(() => {
            this.$alert(
              window.mpApp.$t('aws.onboarding-success'),
              window.mpApp.$t('aws.onboarding-success-title'),
                { customClass: 'success-alert' }
            )

            this.$router.push('/')
          })
          .catch(error => {
            this.$alert(
              window.mpApp.$t('authentication.unexpected-error-dialog-title'),
              window.mpApp.$t('partner.error'),
                { customClass: 'error-alert' }
            )

            captureSentryError(error)
          })
      })
    },
  },
}
</script>
